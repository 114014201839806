import React from 'react'
import ReactGA from 'react-ga'
import {useHistory} from 'react-router-dom'

const ReactGARouteChangeTracker = () => {

  const history = useHistory()

  history.listen(location => {
    ReactGA.set({page: location.pathname})
    ReactGA.pageview(location.pathname)
  })

  return <></>
}

export default ReactGARouteChangeTracker
