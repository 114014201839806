const theme = {
  fontFamily: "Rubik, sans-serif",
  fontSize: '87.5%',
  lineHeight: 1.5,
  
  color: '#f00', // red
  colorDark: '#c00',
  colorAccent: '#fcc',
  colorBlack: 'black',
  colorWhite: 'white',
  colorGray: 'gray',
  colorGrayDark: '#222',
  colorGrayLight: 'silver',
  colorGrayAccent: 'whitesmoke',
  colorTransparent: 'rgba(0,0,0,0)',
  colorGrayTransparent: 'rgba(192,192,192,25%)', // silver, i.e. colorGrayLight
  colorSuccess: '#155724',
  colorSuccessAccent: '#d4edda',
  
  line: '1px',
  lineThick: '2px',

  radius: '4px',

  sizeSmall: '0.3rem',
  sizeHalf: '0.5rem',
  sizeSmaller: '0.80rem',
  size: '1rem',
  sizeLarger: '1.3rem',
  sizeHalfUp: '1.5rem',
  sizeDouble: '2rem',
  sizeLogo: '3rem',

  contentWide: '1140px',
  contentNarrow: '760px',
  contentPadding: '20px',

  breakpoint: '700px'
}

export const globalStyle = `
  html { 
    font-size: ${theme.fontSize}; 
    font-family: ${theme.fontFamily}; 
    line-height: ${theme.lineHeight}; 
  }
  html, body, div, input {
    margin: 0; 
    padding: 0; 
    box-sizing: border-box;
  }
  html, body, #root { 
    height: 100%; 
  }
  h1, h2 {
    font-family: Jost, sans-serif;
  }
  h1 {
    line-height: ${theme.sizeDouble};
  }
  h2 {
    font-weight: 300;
  }
`

export default theme
