import React, {useEffect, useState} from 'react'
import PulseLoader from 'react-spinners/PulseLoader'

import FadeHOC from './FadeHOC'
import theme from '../utils/theme'


interface Props {
  inline?: boolean
}

const TIMEOUT = 1000

const Spinner = ({inline}: Props) => {

  const [underXsec, setUnderXsec] = useState(true)

  useEffect(() => {
    const timer = setTimeout(
      () => setUnderXsec(false),
      TIMEOUT
    )
    return () => clearTimeout(timer)
  }, [])

  return (
    underXsec
      ? null
      : <FadeHOC>
          <PulseLoader size={10} margin={3} color={theme.color} css={inline ? 'margin-left: 14px;' : 'display: block; text-align: center; margin: 100px auto;'} />
        </FadeHOC>
  )
}

export default Spinner
