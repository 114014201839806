import React, {useState, useEffect} from 'react'
import {createGlobalStyle, ThemeProvider} from 'styled-components'
import {Redirect, Route, Switch, useHistory} from 'react-router-dom'

import './utils/i18n'
import Spinner from './ui/Spinner'
import {db, auth} from './utils/firebase'
import {User, Client} from './utils/types'
import {GlobalContext} from './utils/contexts'
import theme, {globalStyle} from './utils/theme'
import {DB, PATH, LOADING} from './utils/constants'
import {isModal, isClient, updateData, getTimestamp} from './utils/helpers'

const Docs = React.lazy(() => import('./Docs'))
const Banner = React.lazy(() => import('./Banner'))
const UserPage = React.lazy(() => import('./User'))
const Landing = React.lazy(() => import('./Landing'))
const ClientPage = React.lazy(() => import('./Client'))
const Terms = React.lazy(() => import('./Legal/Terms'))
const AccountPage = React.lazy(() => import('./Account'))
const Privacy = React.lazy(() => import('./Legal/Privacy'))
const GlobalStyle = createGlobalStyle`${globalStyle}`


const App = () => {

  const history = useHistory()
  const [user, setUser] = useState<User | Client | typeof LOADING | undefined>(LOADING)

  useEffect(() => {

    /*
    // addSizesToDB()
    function addSizesToDB() {

      const brand = 'reserved'
      const brandDisplay = 'Reserved'
      const types = { // type: typeDisplay
        // [DB.ALL]: '',
        'flip-flops': ''
      } as {[type: string]: string}
      const lines = { // line: lineDisplay
        [DB.ALL]: ''
      } as {[type: string]: string}
      const primaryLocale = 'eu'
      // const primaryLocale = 'uk'
      // const primaryLocale = 'us'
      // const primaryLocale = 'sml'
      // const section = DB.SHOES.SECTIONS.MEN
      // const section = DB.SHOES.SECTIONS.WOMEN
      // const section = DB.SHOES.SECTIONS.UNISEX
      const section = DB.SHOES.SECTIONS.KIDS
      const src = 'https://www.reserved.com/nl/en/size-guide'
      const localeSizes = {
        // sml: ['XS','S','M','L','XL'],
        // 'us-men': ['4','5.5','7','8.5','10'],
        // 'us-women': ['5','6.5','8','9.5','11'],
        // us: ['3-4','5-6','7-8','9-10','11-12'],
        uk: ['7/8','8.5/9','10.5/11','11.5/13','13.5/1','1/2','3/3.5','4.5/5'],
        eu: ['24/25','26/27','28/29','30/31','32/33','34/35','36/37','38/39']
      }
      const mmSizes = ['146_7','160_1','173_4','186_7','200_0','213_4','226_7','240_0']

      db.ref(`${DB.SHOES._}/${DB.SHOES.SIZES}/${section}/${brand}`).once('value').then(res => {

        const brandSizes = res.val() || {} as BrandSizes
  
        brandSizes.diff = brandSizes.diff || 0
        brandSizes.displayName = brandSizes.displayName || brandDisplay
        brandSizes.types = brandSizes.types || {}
  
        for (const type in types) {
  
          brandSizes.types[type] = brandSizes.types[type] || {}
          brandSizes.types[type].diff = 0
          brandSizes.types[type].primaryLocale = primaryLocale
          if (types[type]) brandSizes.types[type].displayName = types[type]
          brandSizes.types[type].lines = brandSizes.types[type].lines || {}
  
          for (const line in lines) {
  
            brandSizes.types[type].lines[line] = brandSizes.types[type].lines[line] || {}
            brandSizes.types[type].lines[line].diff = 0
            if (lines[line]) brandSizes.types[type].lines[line].displayName = lines[line]
            if (!Object.keys(types).findIndex(t => t === type) && line === DB.ALL) { // first type with line ALL
              brandSizes.types[type].lines[line].locales = brandSizes.types[type].lines[line].locales || {}
  
              for (const locale in localeSizes) {
                brandSizes.types[type].lines[line].locales![locale] = brandSizes.types[type].lines[line].locales![locale] || {}
  
                for (let i = 0; i < mmSizes.length; i++) {
                  // @ts-ignore
                  brandSizes.types[type].lines[line].locales![locale][mmSizes[i]] = localeSizes[locale][i]
                }
              }
            }
          }
        }
  
        db.ref(`${DB.SHOES._}/${DB.SHOES.SRC}/${section}/${brand}`).set(src)
        db.ref(`${DB.SHOES._}/${DB.SHOES.SIZES}/${section}/${brand}`).update(brandSizes)
      })
    }
    */
  }, [])

  useEffect(() => {

    return auth().onAuthStateChanged(authUser => {

      if (authUser) {

        if (!user || user === LOADING) {

          const id = authUser.uid
  
          db.ref(`/${DB.USERS}/${id}`).off('value')
          db.ref(`/${DB.CLIENTS}/${id}`).off('value')
  
          db.ref(`/${DB.USERS}/${id}`).on('value', data => {
  
            const dbUser = data.val()
  
            if (dbUser) {
  
              setNewUser(dbUser, id)
  
            } else {
              
              db.ref(`/${DB.CLIENTS}/${id}`).on('value', data => {
  
                const dbUser = data.val()
  
                if (dbUser) {
  
                  setNewUser(dbUser, id)
  
                } else { // logged in with a user not yet in DB, e.g. via sign in with google or client signup
  
                  if (history.location.pathname !== PATH.CLIENT_SIGN_UP) { // a client has NOT just signed up
  
                    const userData = {
                      name: authUser.displayName || authUser.email?.split('@')[0],
                      createdOn: getTimestamp(),
                      email: authUser.email
                    }
  
                    updateData(`/${DB.USERS}/${id}`, userData)
                  }
                }
              })
            }
          })
        }

      } else {

        setUser(_user => {

          if (_user && _user !== LOADING) { // user has logged out
            if (history.location.pathname !== PATH.BANNER) { // don't go to HOME when in BANNER
              setTimeout(() => history.push(PATH.HOME), 1) // for some reason, doesn't work without timeout...
            }
          }

          return undefined
        })
      }

      function setNewUser(dbUser: Object, id: string) {

        setUser(_user => {

          const user = {...dbUser, id}

          if (JSON.stringify(user) === JSON.stringify(_user)) {
            return _user
          } else {
            return user
          }
        })
      }
    })
  }, [user, history])

  return (
    <React.Suspense fallback={<Spinner />}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        {user === LOADING
          ? <Spinner />
          : <GlobalContext.Provider value={{user}}>
              <Switch>
                <Route path={PATH.BANNER}>
                  <Banner />
                </Route>
                <Route path={PATH.DOCS}>
                  <Docs />
                </Route>
                <Route path={PATH.LEGAL_PRIVACY}>
                  <Privacy />
                </Route>
                <Route path={PATH.LEGAL_TERMS}>
                  <Terms />
                </Route>
                <Route path={PATH.ACCOUNT}>
                  <AccountPage />
                </Route>
                <Route path={PATH.CLIENT}>
                  <ClientPage client={user as Client} />
                </Route>
                <Route path={PATH.USER}>
                  <UserPage user={user as User} />
                </Route>
                <Route exact path={PATH.HOME}>
                  {user
                    ? isClient(user)
                      ? <Redirect to={PATH.CLIENT} />
                      : <Redirect to={PATH.USER} />
                    : isModal() 
                      ? <Redirect to={PATH.USER_SIGN_UP} />
                      : <Landing />
                  }
                </Route>
              </Switch>
            </GlobalContext.Provider>
        }
      </ThemeProvider>
    </React.Suspense>
  )
}

export default App
