import React from 'react'
import ReactGA from 'react-ga'
import ReactDOM from 'react-dom'
import * as serviceWorker from './utils/serviceWorker'
import {BrowserRouter as Router} from 'react-router-dom'

import App from './App'
import ReactGARouteChangeTracker from './utils/ReactGARouteChangeTracker'


ReactGA.initialize('UA-195720273-1', {
  // debug: process.env.NODE_ENV !== 'production',
  testMode: process.env.NODE_ENV !== 'production'
})

ReactDOM.render(
  // <React.StrictMode>
    <Router>
      <App />
      <ReactGARouteChangeTracker />
    </Router>,
  // </React.StrictMode>
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
