import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import Transition from 'react-transition-group/Transition'


interface Props {
  children: React.ReactNode
  className?: string
}

const Container = styled.div`
  margin: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  transition: opacity 500ms;
  padding: ${({theme}) => theme.line};
  &.entered {
    opacity: 1;
  }
`

const FadeHOC = ({className, children}: Props) => {

  const [show, setShow] = useState(false)

  useEffect(() => {
    setShow(true)
  }, [])

  return (
    <Transition in={show} timeout={0}>
      {status =>
        <Container className={className ? `${className} ${status}` : `${status}`}>
          {children}
        </Container>
      }
    </Transition>
  )
}

export default FadeHOC
