import React from 'react'

import {User, Client, UserTeam} from './types'


export const GlobalContext = React.createContext<{user?: User | Client}>({})

export const UserContext = React.createContext<{
  team?: UserTeam,
  clearTeam: () => void,
  promptVisible: boolean,
  setPromptVisible: Function
}>({
  clearTeam: () => {},
  promptVisible: false,
  setPromptVisible: () => {}
})
