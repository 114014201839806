import i18next from 'i18next'
import ReactGA from 'react-ga'

import i18n from './i18n'
import firebase, {db, auth} from './firebase'
import {DB, LOADING, LOCAL_STORAGE_KEY, SESSION_STORAGE_KEY, SIZES} from './constants'
import {User, Client, RecommendationType, ShoesSizes, LineLocales, LocaleSizes, ShoesSizesCustomClient} from './types'


// UTILS

export const getCurrentUser = (): firebase.User | null => {

  return auth().currentUser
}

export const toTitleCase = (phrase: string): string => {
  
  return phrase
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.substring(1))
    .join(' ')
}

export const toSentenceCase = (string: string) => {
  
  return string.charAt(0).toUpperCase() + string.substring(1)
}

export const isEmail = (email: string): boolean => {

  if (email) {
    const emailExp = /\S+@\S+\.\S+/
    return emailExp.test(email)
  }

  return false
}

export const isClient = (user?: User | Client | typeof LOADING): boolean => {

  return !!user?.hasOwnProperty('url')
}

export const isAnonymousUser = (user: User | Client): boolean => {

  return !isClient(user) && !user?.email
}

export const isModal = (): boolean => {

  return window.self !== window.top
}

export const setShopLanguage = (language: string) => {

  window.localStorage.setItem(LOCAL_STORAGE_KEY.SHOP_LANGUAGE, language)
  setLanguage(language)
}

export const setUserLanguage = (language: string) => {

  window.localStorage.setItem(LOCAL_STORAGE_KEY.USER_LANGUAGE, language)
  setLanguage()
}

const setLanguage = (language = getLanguage()) => {

  if (language) {
    i18next.changeLanguage(language)
  }
}

export const getLanguage = (): string => {

  return window.localStorage.getItem(LOCAL_STORAGE_KEY.USER_LANGUAGE) || window.localStorage.getItem(LOCAL_STORAGE_KEY.SHOP_LANGUAGE) || i18n?.language
  // return window.localStorage.getItem(LOCAL_STORAGE_KEY.USER_LANGUAGE) || window.localStorage.getItem(LOCAL_STORAGE_KEY.SHOP_LANGUAGE) || 'en'
}

export const postMessage = (message: object) => {

  if (document.referrer) {

    const referrerURL = new URL(document.referrer)
  
    window.parent.postMessage(
      message, 
      referrerURL.origin
    )
  }
}

export const closeModal = () => {

  postMessage({closeModal: true})
}

export const emailVerified = (): boolean => {

  const currentUser = getCurrentUser()

  if (currentUser) {
    return currentUser.emailVerified
  } else {
    return true
  }
}

export const getHostname = (url: string): string => {

  return (new URL(url)).hostname
}

export const getTimestamp = (): Object => {

  return firebase.database.ServerValue.TIMESTAMP
}

export const getSessionStorage = (key: string): string | RecommendationType => {
  
  return JSON.parse(window.sessionStorage.getItem(key) || "\"\"") // "\"\"" returns an empty string ""
}

export const setSessionStorage = (key: string, value?: any) => {

  return value 
    ? window.sessionStorage.setItem(key, JSON.stringify(value))
    : window.sessionStorage.removeItem(key)
}


// DATA

export const signOut = () => {

  auth().signOut().then(() => {
    setSessionStorage(SESSION_STORAGE_KEY.RECOMMENDATION)
    isModal() && closeModal()
  })
}

export const signInWithGoogle = () => {

  const google = new firebase.auth.GoogleAuthProvider()
  auth().languageCode = i18next.language
  auth().signInWithPopup(google)
}

export const signUpWithGoogle = (callback: (user: firebase.User | null) => void) => {

  const google = new firebase.auth.GoogleAuthProvider()
  auth().languageCode = i18next.language
  auth().currentUser?.linkWithPopup(google)
    .then(({user}) => callback(user))
    .catch(error => console.error(error))
}

export const getData = async (path: string): Promise<any> => {
  
  const snapshot = await db.ref(path).once('value')
  return snapshot.val()
}

export const updateData = async (path: string, data: any): Promise<any> => {
  
  try {
    return db.ref(path)
      .update({ ...data, updatedOn: getTimestamp()})
  } catch (error) {
    return console.error(error)
  }
}

export const setData = async (path: string, data: any): Promise<any> => {
  
  try {
    return db.ref(path).set(data)
  } catch (error) {
    return console.error(error)
  }
}

export const incrementData = (path: string) => {
  
  // console.log('INCREMENT ' + path);
  db.ref(path).transaction(count => count + 1)
}

export const deleteData = (path: string) => {
  
  // console.log('DELETE ' + path);
  return db.ref(path).remove()
}

export const callCloudFunction = (functionName: string, data: any) => {

  const send = firebase.functions().httpsCallable(functionName)
  send(data)
}

export const deleteAuthUser = () => {

  const user = auth().currentUser
  
  if (user) {

    deleteData(`${DB.USERS}/${user.uid}`)
    .then(() => user.delete())
    .then(() => auth().signOut())
    .then(() => isModal() && closeModal())
    .catch(error => console.error(error))
  }
}

export const sendVerificationEmail = async (): Promise<void> => {

  const currentUser = getCurrentUser()

  if (currentUser) {
    auth().languageCode = getLanguage()
    try {
      return currentUser.sendEmailVerification()
    } catch (error) {
      return console.error(error)
    }
  } else {
    return Promise.reject()
  }
}

export const getShoesSizesForUser = ({shoes, section}: User, callback: (shoesSizes: ShoesSizes) => void) => {

  getData(`/${DB.SHOES._}/${DB.SHOES.SIZES}/`).then((shoesSizes: ShoesSizes) => {

    if (shoes && section) {
      shoes.forEach(shoe => {
        const {brand, type, line} = shoe
        if (
          !Object.keys(shoesSizes[section] || {}).includes(brand) || 
          !Object.keys(shoesSizes[section][brand]?.types[type] || {}).includes(type) || 
          !Object.keys(shoesSizes[section][brand]?.types[type]?.lines || {}).includes(line)
        ) {
          updateShoesSizes(shoesSizes, section, shoe)
        }
      })
    }

    callback(shoesSizes)
  })
}

export const getShoesSizesForClient = (clientId: string, callback: (shoesSizes: ShoesSizes) => void) => {

  getData(`/${DB.SHOES._}/${DB.SHOES.SIZES}/`).then((shoesSizes: ShoesSizes) => {
    getData(`${DB.SHOES._}/${DB.SHOES.SIZES_CUSTOM_CLIENTS}/${clientId}`).then((sizes: ShoesSizesCustomClient) => {
      for (const section in sizes) {
        for (const brand in sizes[section]) {
          for (const type in sizes[section][brand]) {
            for (const line in sizes[section][brand][type]) {
              shoesSizes[section][brand] = shoesSizes[section][brand] || {}
              shoesSizes[section][brand].diff = shoesSizes[section][brand].diff || 0
              shoesSizes[section][brand].types = shoesSizes[section][brand].types || {}
              shoesSizes[section][brand].types[type] = shoesSizes[section][brand].types[type] || {}
              shoesSizes[section][brand].types[type].diff = shoesSizes[section][brand].types[type].diff || 0
              shoesSizes[section][brand].types[type].primaryLocale = shoesSizes[section][brand].types[type].primaryLocale || shoesSizes[section][brand].types[DB.ALL].primaryLocale
              shoesSizes[section][brand].types[type].lines = shoesSizes[section][brand].types[type].lines || {}
              shoesSizes[section][brand].types[type].lines[line] = shoesSizes[section][brand].types[type].lines[line] || {}
              shoesSizes[section][brand].types[type].lines[line].diff = shoesSizes[section][brand].types[type].lines[line].diff || 0
              shoesSizes[section][brand].types[type].lines[DB.ALL] = shoesSizes[section][brand].types[type].lines[DB.ALL] || {}
              shoesSizes[section][brand].types[type].lines[DB.ALL].diff = shoesSizes[section][brand].types[type].lines[DB.ALL].diff || 0
            }
          }
        }
      }

      callback(shoesSizes)
    })
  })
}

export const updateShoesSizes = (shoesSizes: ShoesSizes, section: string, {brand, type = DB.ALL, line = DB.ALL, brandCustom, typeCustom, lineCustom}: {brand: string, type: string, line: string, brandCustom?: string, typeCustom?: string, lineCustom?: string}): ShoesSizes => {

  const eu = {} as LocaleSizes
  const uk = {} as LocaleSizes
  const us = {} as LocaleSizes
  const sml = {} as LocaleSizes

  SIZES.EU.forEach((size, index) => eu[index] = size)
  SIZES.US_UK.forEach((size, index) => uk[index] = size)
  SIZES.US_UK.forEach((size, index) => us[index] = size)
  SIZES.SML.forEach((size, index) => sml[index] = size)

  shoesSizes[section][brand] = shoesSizes[section][brand] || {}
  shoesSizes[section][brand].diff = shoesSizes[section][brand].diff || 0
  shoesSizes[section][brand].displayName = shoesSizes[section][brand].displayName || brandCustom || brand
  shoesSizes[section][brand].types = shoesSizes[section][brand].types || {}
  shoesSizes[section][brand].types[type] = shoesSizes[section][brand].types[type] || {}
  shoesSizes[section][brand].types[type].diff = shoesSizes[section][brand].types[type].diff || 0
  shoesSizes[section][brand].types[type].displayName = shoesSizes[section][brand].types[type].displayName || typeCustom || type
  shoesSizes[section][brand].types[type].lines = shoesSizes[section][brand].types[type].lines || {}
  shoesSizes[section][brand].types[type].lines[line] = shoesSizes[section][brand].types[type].lines[line] || {}
  shoesSizes[section][brand].types[type].lines[line].diff = shoesSizes[section][brand].types[type].lines[line].diff || 0
  shoesSizes[section][brand].types[type].lines[line].displayName = shoesSizes[section][brand].types[type].lines[line].displayName || lineCustom || (line === DB.ALL ? i18n.t('shoe.lineAll') : line)
  shoesSizes[section][brand].types[type].lines[line].locales = shoesSizes[section][brand].types[type].lines[line].locales || shoesSizes[section][brand].types[type].lines[DB.ALL]?.locales || {eu, uk, us, sml}

  return shoesSizes
}

export const getLineLocales = (shoesSizes: ShoesSizes, section: string, {brand, type, line}: {brand: string, type: string, line: string}): LineLocales => {

  return (
    shoesSizes[section][brand]?.types[type]?.lines[line || DB.ALL]?.locales ||
    shoesSizes[section][brand]?.types[type]?.lines[DB.ALL]?.locales || 
    shoesSizes[section][brand]?.types[DB.ALL]?.lines[DB.ALL]?.locales
  ) || {}
}

// export const getLineDisplayName = (shoesSizes: ShoesSizes, {section, brand, type, line}: {section: string, brand: string, type: string, line: string}): string => {

//   if (line === DB.ALL) {
//     return i18n.t('shoe.lineAll')
//   }

//   return shoesSizes[section][brand].types[type]?.lines?.[line]?.displayName || line
// }

export const getSize = (size: string): number => {

  return Number(size.replace('_','.'))
}


// ANALYTICS

export const sendReactGAEvent = (data: {category: string, action: string}) => {

  if (auth().currentUser?.uid !== '5zXJBZi5o8N9ygwE4JJDq9VpRRk1') { // artooras@gmail.com
    ReactGA.event(data)
  }
}
