import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/functions'


firebase.initializeApp({
  projectId: 'rightsize-d4fc1',
  storageBucket: 'rightsize-d4fc1.appspot.com',
  authDomain: 'rightsize-d4fc1.firebaseapp.com',
  apiKey: 'AIzaSyB1JkZ2DcY8MLcxmazQGEfDuyG0Q45ZK5Q',
  databaseURL: 'https://rightsize-d4fc1.firebaseio.com'
})

const db = firebase.database()
const auth = firebase.auth

if (window.location.hostname === 'localhost') {
  db.useEmulator('localhost', 9000)
  auth().useEmulator('http://localhost:9099/')
  firebase.functions().useEmulator('localhost', 5001)
}

export default firebase
export {db, auth}
