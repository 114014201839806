export const APP_NAME = 'RightSize'

export const ANONYMOUS = 'anonymous'

export const LOADING = 'loading...'

export const ICON = {
  DONE: 'done',
  CLOSE: 'close',
  DELETE: 'delete',
  INFO: 'info_outline',
  EMAIL: 'mail_outline',
  ERROR: 'error_outline',
  SUCCESS: 'check_circle_outline',
  CHECKBOX_CHECKED: 'check_circle',
  CHECKBOX: 'radio_button_unchecked'
}

export const KEY = {
  ENTER: 'Enter'
}

export const SESSION_STORAGE_KEY = {
  SECTION: 'rs_section',
  HOSTNAME: 'rs_hostname',
  REFERRER: 'rs_referrer',
  RECOMMENDATION: 'rs_rec'
}

export const LOCAL_STORAGE_KEY = {
  I18N_LANGUAGE: 'i18nextLng',
  USER_LANGUAGE: 'rs_user_language',
  SHOP_LANGUAGE: 'rs_shop_language'
}

export const DB = {

  ALL: 'all',
  
  SHOWS: 'shows',
  USERS: 'users',
  CLIENTS: 'clients',

  USER: {
    REF: 'ref',
    NAME: 'name',
    TEAM: 'team',
    EMAIL: 'email',
    SHOES: 'shoes',
    SECTION: 'section',
    MEASUREMENT: {
      _: 'measurement',
      MM: 'mm',
      VALUE: 'value',
      SYSTEM: 'system'
    },
    SHOE: {
      LINE: 'line',
      TYPE: 'type',
      SIZE: 'size',
      BRAND: 'brand',
      LOCALE: 'locale',
      SIZE_VALUE: 'sizeValue'
    }
  },

  CLIENT: {
    URL: 'url',
    NAME: 'name',
    ID: 'clientId',
    EMAIL: 'email',
    USERS: 'users',
    HOSTNAME: 'hostname',
    CREATED_ON: 'createdOn',
    SHOWS_COUNT: 'showsCount'
  },

  SHOES: {
    _: 'shoes',
    SRC: 'src',
    SIZES: 'sizes',
    NAMES: {
      _: 'names',
      BRANDS: 'brands',
      LINES: 'lines'
    },
    DIFFS: 'diffs',
    DIFFS_DATA: 'diffsData',
    PRIMARY_LOCALES: 'primaryLocales',
    SIZES_CUSTOM_USERS: 'sizesCustomUsers',
    SIZES_CUSTOM_CLIENTS: 'sizesCustomClients',
    SIZES_CUSTOM_CLIENT_USERS: 'sizesCustomClientUsers',
    LOCALES: {
      EU: 'eu',
      US: 'us',
      UK: 'uk',
      SML: 'sml',
      'US-MEN': 'us-men',
      'US-WOMEN': 'us-women'
    },
    SECTIONS: {
      MEN: 'men',
      KIDS: 'kids',
      WOMEN: 'women',
      UNISEX: 'unisex'
    }
  }
}

export const MEASUREMENT_SYSTEM = {
  METRIC: 'metric',
  IMPERIAL: 'imperial'
}

export const EMPTY_MEASUREMENT = {
  mm: 0, value: '', system: MEASUREMENT_SYSTEM.METRIC
}

export const PATH = {
  HOME: '/',
  DOCS: '/docs',
  BANNER: '/banner',
  ACCOUNT: '/account',
  ACCOUNT_VERIFY_EMAIL: '/account/verifyEmail',
  ACCOUNT_RESET_PASSWORD: '/account/resetPassword',
  ACCOUNT_CREATE_PASSWORD: '/account/createPassword',
  USER: '/user',
  USER_HOME: '/user/home',
  USER_SHOES: '/user/home/shoes',
  USER_PROFILE: '/user/home/profile',
  USER_MEASUREMENT: '/user/home/measurement',
  USER_SIGN_IN: '/user/signin',
  USER_SIGN_UP: '/user/signup',
  USER_SIGN_UP_WITH_EMAIL: '/user/signup/createAccount',
  CLIENT: '/client',
  CLIENT_DOCS: '/client/docs',
  CLIENT_TEAM: '/client/team',
  CLIENT_SIZES: '/client/sizes',
  CLIENT_STATS: '/client/stats',
  CLIENT_PROFILE: '/client/profile',
  CLIENT_SIGN_IN: '/client/signin',
  CLIENT_SIGN_UP: '/client/signup',
  LEGAL_TERMS: '/legal/terms',
  LEGAL_PRIVACY: '/legal/privacy'
}

export const QUERY_KEYS = {
  MODE: 'mode',
  CLIENT_REF: 'ciu',
  ACTION_CODE: 'oobCode'
}

export const QUERY_VALUES = {
  VERIFY_EMAIL: 'verifyEmail',
  RESET_PASSWORD: 'resetPassword'
}

export const BANNER_CONTENT = {
  USER: 'user',
  NO_USER: 'no-user',
  NO_SHOE: 'no-shoe',
  NO_SIZE: 'no-size',
  NO_PARAMS: 'no-params',
  BAD_HOSTNAME: 'bad-hostname',
  PARAM_ERRORS: 'param-errors',
  USER_INCOMPLETE: 'user-incomplete',
  WRONG_SECTION_MEN: 'wrong-section-men',
  WRONG_SECTION_WOMEN: 'wrong-section-women'
}

export const CLOUD_FUNCTION = {
  CLIENT_INVITE_USER: 'clientInviteUser'
}

export const REGEX = {
  PUNCTUATION: /[.,/#!$%^&*;:{}=\-_`~()]/g
}

export const LANGUAGES = [
  'en',
  'lt'
]

export const SIZES = {
  
  IMPERIAL: [
    '8',
    '8 1/8',
    '8 2/8',
    '8 3/8',
    '8 4/8',
    '8 5/8',
    '8 6/8',
    '8 7/8',
    '9',
    '9 1/8',
    '9 2/8',
    '9 3/8',
    '9 4/8',
    '9 5/8',
    '9 6/8',
    '9 7/8',
    '10',
    '10 1/8',
    '10 2/8',
    '10 3/8',
    '10 4/8',
    '10 5/8',
    '10 6/8',
    '10 7/8',
    '11',
    '11 1/8',
    '11 2/8',
    '11 3/8',
    '11 4/8',
    '11 5/8',
    '11 6/8',
    '11 7/8',
    '12',
    '12 1/8',
    '12 2/8',
    '12 3/8',
    '12 4/8',
    '12 5/8',
    '12 6/8',
    '12 7/8',
    '13',
    '13 1/8',
    '13 2/8',
    '13 3/8',
    '13 4/8',
    '13 5/8',
    '13 6/8',
    '13 7/8'
  ],

  US_UK: [
    '3',
    '3.5',
    '4',
    '4.5',
    '5',
    '5.5',
    '6',
    '6.5',
    '7',
    '7.5',
    '8',
    '8.5',
    '9',
    '9.5',
    '10',
    '10.5',
    '11',
    '11.5',
    '12',
    '12.5',
    '13',
    '13.5',
    '14',
    '14.5',
    '15',
    '15.5',
    '16',
    '16.5',
    '17',
    '17.5',
    '18'
  ],

  EU: [
    '36',
    '36 1/3',
    '36 1/2',
    '36 2/3',
    '37',
    '37 1/3',
    '37 1/2',
    '37 2/3',
    '38',
    '38 1/3',
    '38 1/2',
    '38 2/3',
    '39',
    '39 1/3',
    '39 1/2',
    '39 2/3',
    '40',
    '40 1/3',
    '40 1/2',
    '40 2/3',
    '41',
    '41 1/3',
    '41 1/2',
    '41 2/3',
    '42',
    '42 1/3',
    '42 1/2',
    '42 2/3',
    '43',
    '43 1/3',
    '43 1/2',
    '43 2/3',
    '44',
    '44 1/3',
    '44 1/2',
    '44 2/3',
    '45',
    '45 1/3',
    '45 1/2',
    '45 2/3',
    '46',
    '46 1/3',
    '46 1/2',
    '46 2/3',
    '47',
    '47 1/3',
    '47 1/2',
    '47 2/3',
    '48',
    '48 1/3',
    '48 1/2',
    '48 2/3',
    '49',
    '49 1/3',
    '49 1/2',
    '49 2/3',
    '50',
    '50 1/3',
    '50 1/2',
    '50 2/3',
    '51',
    '51 1/3',
    '51 1/2',
    '51 2/3',
    '52',
    '52 1/3',
    '52 1/2',
    '52 2/3',
    '53',
    '53 1/3',
    '53 1/2',
    '53 2/3',
    '54',
    '54 1/3',
    '54 1/2',
    '54 2/3'
  ],

  SML: [
    'XS',
    'S',
    'M',
    'L',
    'XL',
    'XXL',
    'XXXL'
  ]
}
